import { getPaginated } from '@/api/implementation/app';
import { camelToSnake } from '../util/keyConverter.js';

function fetchAll(page = 1, perPage = 10, search, sortBy, descending, filters) {
  return getPaginated(`product`, page, perPage, search, sortBy, descending, filters ? camelToSnake(filters) : undefined);
}

function fetch(code = undefined) {
  return fetchAll(1,
    1,
    undefined,
    undefined,
    undefined,
    {
      code,
    },
  );
}

export const getImplementations = productId => {
  return getPaginated(`/product/${productId}/related-products`, 1, 4);
}

export {
  fetchAll,
  fetch,
};
