<template>
  <v-card width="100%">
    <v-row class="ma-0">
      <v-col cols="4">
        <VImg contain :src="media" aspect-ratio="1" @click="dialog=true"/>
      </v-col>
      <v-col cols="8">
        <div class="text-h6">{{ product.name }}</div>
        <div class="text-body-2">Artikel nummer: {{ product.code }}</div>
        <div class="text-body-2">Levertijd: 2-3 dagen</div>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="8" align-self="start">
      <v-row class="mt-3" v-if="hasUpdateFields">
        <v-col cols="6">
          <v-btn text class="pa-0" color="grey" @click="removeOrderLine(product)">
            <v-icon class="mr-2">far fa-trash-alt</v-icon>
            Verwijderen
          </v-btn>
        </v-col>
        <v-col cols="4">
          <VTextField type="number"
                      label="Aantal"
                      outlined
                      dense
                      :rules="[v => !!v && v > 0 || 'Het aantal moet een minimaal van 1 hebben']"
                      hide-details
                      v-model="quantity"
                      @input="handleInput"/>
        </v-col>
      </v-row>
      <div class="text-body-2" v-else>Aantal: {{ product.quantity }}</div>
      </v-col>
      <v-col cols="4" align-self="end" class="text-right">
        <div class="text-body-2 strike-through ml-5" v-if="showAlternativePrice && product.alternativePrice">
          {{ product.price | currency }}
        </div>
        <div class="text-h6" v-if="showAlternativePrice && product.alternativePrice">
          {{ product.alternativePrice | currency }}
        </div>
        <div class="text-h6" v-else>{{ product.price | currency }}</div>
        <div class="text-body-2">Excl BTW</div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="60%">
      <VImg contain :src="largeMedia" :lazy-src="media"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import { getPlaceholder } from '@/application/util/imagePlaceholder.js';

export default {
  name: 'CartCard',
  props: {
    product: {
      type: Object,
      required: true,
    },
    hasUpdateFields: {
      type: Boolean,
    },
    showAlternativePrice: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      quantity: 0,
    };
  },
  created() {
    this.quantity = this.product.quantity;
  },
  computed: {
    media() {
      return this.product.mainImage?.url ?? getPlaceholder();
    },
    largeMedia() {
      return this.product.mainImage?.url ?? getPlaceholder(1024);
    },
  },
  methods: {
    ...mapMutations({
      updateOrderLineQuantity: 'order/updateOrderLineQuantity',
      removeOrderLine: 'order/removeOrderLine',
    }),
    handleInput() {
      this.updateOrderLineQuantity({
        product: this.product,
        quantity: this.quantity,
      });
    },
  },
};
</script>
