<template>
  <div>
    <v-row no-gutters>
      <span>Subtotaal:</span>
      <VSpacer/>
      <span>{{ subTotal | currency }}</span>
    </v-row>
    <v-row class="py-3" no-gutters>
      <span>Verzendkosten:</span>
      <VSpacer/>
      <span>{{ shippingCost | currency }}</span>
    </v-row>
    <v-row class="py-3" no-gutters>
      <span>BTW:</span>
      <VSpacer/>
      <span>21%</span>
    </v-row>
    <VDivider/>
    <v-row class="pt-3" no-gutters>
      <span>Totaal:</span>
      <VSpacer/>
      <span>{{ total | currency }}</span>
    </v-row>
    <v-row class="pb-3" no-gutters justify="end">
      <span>Incl. BTW</span>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { deliveryTypes } from '@/application/enums/deliveryType.js';

export default {
  name: 'CartCosts',
  props: {
    orderLines: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('order', ['expressDelivery', 'isPickUp']),
    subTotal() {
      return this.orderLines.reduce((total, current) => total + ((current.alternativePrice || current.price) * current.quantity), 0);
    },
    total() {
      return (this.subTotal + this.shippingCost) * 1.21;
    },
    shippingCost() {
      if (this.isPickUp || this.subTotal >= 300) {
        return 0;
      }
      if (this.expressDelivery === deliveryTypes.EXPRESS) {
        return 100;
      }
      return 0;
    },
    isExpressDelivery: {
      get() {
        return this.expressDelivery;
      },
      set(value) {
        this.setExpressDelivery(value);
      },
    },
  },
  methods: {
    ...mapMutations('order', [
      'setExpressDelivery',
    ]),
  },
};
</script>

<style scoped>

</style>
