<template>
  <v-row justify-md="center" class="ma-0">
    <v-col md="6" justify-md="center">
      <v-row v-for="orderLine in orderLines" :key="orderLine.id" class="my-3 mx-0">
        <CartCard :product="orderLine" has-update-fields/>
      </v-row>
      <CartEmptyCard v-if="!hasOrderLines" class="mb-3"/>
      <v-row no-gutters class="my-12">
        <v-col cols="6" offset-md="2">
          <!-- TODO: #FIS-66
          <v-row class="my-3">
            <v-icon class="mr-2">far fa-check</v-icon>
            <span>Wij doen ons best</span>
          </v-row>
          <v-row class="my-3">
            <v-icon class="mr-2">far fa-check</v-icon>
            <span>Echt waar</span>
          </v-row>-->
        </v-col>
        <v-col cols="4" class="pa-0">
          <CartCosts :order-lines="orderLines"/>

          <v-btn color="primary" class="my-6" block :to="{name:'make-order'}" :disabled="!hasOrderLines">
            Bestellen
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CartEmptyCard from '@/components/CartEmptyCard.vue';
import { mapGetters, mapMutations } from 'vuex';
import CartCard from '@/components/CartCard.vue';
import CartCosts from '@/components/CartCosts.vue';
import { fetchAll } from '@/api/product/product.js';

export default {
  name: 'ShoppingCart',
  components: {
    CartCosts,
    CartEmptyCard,
    CartCard,
  },
  created() {
    this.$hero.$emit('setTitle', 'winkelwagen');
    this.$hero.$emit('setCanSearch', false);
    this.$hero.$emit('setBreadcrumbs', [
      {
        text: 'home',
        exact: true,
        to: { name: 'home' },
      },
      {
        text: 'winkelwagen',
        to: { name: 'shopping-cart' },
      },
    ]);

    // prices of products can be different when user is logged in
    this.updateOrderLinesInShoppingCart();
  },
  data: () => ({
    filters: {
      codes: [],
    },
  }),
  computed: {
    ...mapGetters('order', ['orderLines', 'hasOrderLines', 'beenSyncedWithProfile']),
    ...mapGetters('authorisation', ['isLoggedIn']),
    codes() {
      return this.hasOrderLines ? this.orderLines.reduce((arr, value) => {
        if (value && value.code) {
          arr.push(value.code);
        }
        return arr;
      }, []) : [];
    },
  },
  methods: {
    ...mapMutations('order', ['setBeenSyncedWithProfile', 'updateOrderLinePrice']),
    updateOrderLinesInShoppingCart() {
      if (!this.beenSyncedWithProfile && this.isLoggedIn) {
        this.syncShoppingCartWithProfile();
      } else if (!this.isLoggedIn) {
        this.setBeenSyncedWithProfile(false);
      }
    },
    async syncShoppingCartWithProfile() {
      if (this.codes) {
        this.filters.codes = `${this.codes.join(',')}`;
        const fetchedProducts = await fetchAll(
            1,
            this.codes.length,
            undefined,
            undefined,
            undefined,
            this.filters,
        );

        for (let product of fetchedProducts.data) {
          product.price /= 100;
          product.alternativePrice /= 100;
          this.updateOrderLinePrice(product);
        }

        this.setBeenSyncedWithProfile(true);
      }
    },
  },
};
</script>

<style scoped>

</style>
