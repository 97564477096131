<template>
  <v-card width="100%">
    <v-card-text>
      <v-row justify="start" align="center" no-gutters>
        <v-icon size="55" class="mx-4">fal fa-shopping-cart</v-icon>
        <h3 class="text-h5">Er staan nog geen producten in de winkelwagen</h3>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CartEmptyCard',
};
</script>
